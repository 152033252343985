.about-content .element{
    margin-bottom: 50px;
}

.element h1.element-title{
    text-align: left;
    color: var(--dark_text);
    font-family: "Nunito-Black";
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.element .language-card-container{
    display: flex;
    overflow-x: auto;
}

.language-card-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 35px;
}

.language-card-wrapper:last-of-type{
    margin-right: 0;
}

.language-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.language-card img{
    width: 30px;
    height: 30px;
    margin-bottom: 12px;
}

.language-card h3{
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Nunito-Bold";
    color: var(--dark_text);
    text-align: center;
}

.biography-wrapper{
    background: linear-gradient(180deg,#fff,rgba(48,167,210,0.25));
}

.biography{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 0;
}

.biography img{
    border-radius: 100%;
    margin-bottom: 25px;
    border: 2px solid var(--dark_text);
    width: 90%;
    height: auto;
}

.biography p{
    font-size: 14px !important;
    line-height: 1.75em;
    letter-spacing: 0.75px;
    font-family: monospace;
    font-weight: bold;
    color: var(--dark_text);
    font-family: "Nunito-Regular";
}

.biography p::first-letter{
    color: rgba(17,151,209);
    font-weight: bolder;
    font-size: 4em;
    text-shadow: 2px 2px var(--dark_text);
}

.work-list{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
}

@media screen and (min-width: 475px) {
    .element .language-card-container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .language-card img{
        width: 40px;
        height: 40px;
    }
    .language-card-wrapper{
        margin-right: 75px;
    }    
}

@media screen and (min-width: 800px) {
    .element h1.element-title{
        font-size: 2em;
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 1100px) {
    .element .language-card-container{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    .language-card img{
        width: 50px;
        height: 50px;
    }
    .biography-wrapper{
        background: linear-gradient(90deg,#fff,rgba(48,167,210,0.25));
    }
    .biography{
        flex-direction: row;
    }
    .biography img{
        width: 400px;
        margin-bottom: 0;
        margin-right: 50px;
    }
    .biography p{
        font-size: 18px !important;
    }
    .work-list{
        grid-template-columns: 1fr 1fr;   
    }
}

@media screen and (min-width: 1500px) {
    .element .language-card-container{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    .language-card img{
        width: 50px;
        height: 50px;
    }
    .biography img{
        width: 450px;
        margin-bottom: 0;
    }
    .work-list{
        grid-template-columns: 1fr 1fr 1fr;   
    }
}