:root {
  --dark_text: #0d172a;
  --transparent_dark: rgba(13, 23, 42, .8);
  --more_transparent_dark: rgba(13, 23, 42, .25);
  --transition: all .25s cubic-bezier(.4, 0, .2, 1)
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
  list-style-type: none;
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6{
  line-height: 1;
}

html{
  scroll-behavior: smooth;
}

html, body, #root{
  overflow-x: hidden;
}

section{
  position: relative;
  padding-top: 0;
  padding-bottom: var(--navbar_height);
  min-height: 100vh;
}

.custom-button{
  width: 100%;
  background-color: #fff;
  border: 0 solid #e2e8f0;
  color: #0d172a;
  cursor: pointer;
  display: inline-block;
  font-family: "Nunito-SemiBold";
  font-size: 1rem;
  line-height: 1;
  padding: 1rem 0rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  text-decoration-thickness: auto;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 2px 5px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.custom-button:hover {
  background-color: #1e293b;
  color: #fff;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark_text); 
}

@media screen and (min-width: 800px) {
  section{
    padding-top: var(--navbar_height);
    padding-bottom: 0;
  }
  .custom-button {
    font-size: 1.125rem;
    padding: 1rem 5rem;
  }
}

/* todo: FONTS ADDED */
@font-face {
  font-family: "Nunito-Black";
  src:  url("./assets/fonts/Nunito-Black.ttf") format('truetype'),
        url('./assets/fonts/Nunito-Black.woff') format('woff');
}

@font-face {
  font-family: "Nunito-Bold";
  src:  url("./assets/fonts/Nunito-Bold.ttf") format('truetype'),
        url('./assets/fonts/Nunito-Bold.woff') format('woff');;
}

@font-face {
  font-family: "Nunito-SemiBold";
  src:  url("./assets/fonts/Nunito-SemiBold.ttf") format('truetype'),
        url('./assets/fonts/Nunito-SemiBold.woff') format('woff');
}

@font-face {
  font-family: "Nunito-Medium";
  src:  url("./assets/fonts/Nunito-Medium.ttf") format('truetype'),
        url('./assets/fonts/Nunito-Medium.woff') format('woff');
}

@font-face {
  font-family: "Nunito-Regular";
  src:  url("./assets/fonts/Nunito-Regular.ttf") format('truetype'),
        url('./assets/fonts/Nunito-Regular.woff') format('woff');
}

@font-face {
  font-family: "Nunito-Light";
  src:  url("./assets/fonts/Nunito-Light.ttf") format('truetype'),
        url('./assets/fonts/Nunito-Light.woff') format('woff');
}