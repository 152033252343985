:root {
    --navbar_height: 45px;
    --navbar_options_width: 150px;
}

nav{
    position: fixed;
    /*top: calc(100% - var(--navbar_height));*/
    bottom: 0;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0px -5px 8px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px -5px 8px 0px rgba(0,0,0,0.25);
    box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.25);
    background-color: white;
    z-index: 99999;
    transition: all .25s cubic-bezier(.4, 0, .2, 1);
}

.navbar-inner{
    height: var(--navbar_height);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-inner .logo{
    width: var(--navbar_height);
    height: var(--navbar_height);
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.navbar-inner .logo h1{
    font-size: 2.5em;
    font-family: "Nunito-Bold";
    color: var(--dark_text);
    letter-spacing: -2.5px;
}

.navbar-inner ul{
    height: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.navbar-inner ul li{
    position: relative;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .25s cubic-bezier(.4, 0, .2, 1);
}

.navbar-inner ul li a{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.navbar-inner ul li a.active{
    background-color: var(--dark_text);
}

.navbar-inner ul li:not(.drop-down):hover{
    background-color: white;
}    

.navbar-inner ul li.drop-down{
    flex: 2;
}

.navbar-inner ul li h1{
    font-family: "Nunito-Bold";
    font-size: 16px;
    color: var(--dark_text);
    display: none;
    user-select: none;
    transition: all .25s cubic-bezier(.4, 0, .2, 1);
}

.navbar-inner ul li .icon{
    width: calc(var(--navbar_height) / 2.1);
    height: calc(var(--navbar_height) / 2.1);
}

.navbar-inner ul li .icon img{
    max-width: 100%;
    max-height: 100%;
}

@media screen and (min-width: 475px) {
    :root {
        --navbar_height: 50px;
    }
    .navbar-inner ul li .icon{
        width: calc(var(--navbar_height) / 2.1);
        height: calc(var(--navbar_height) / 2.1);
    }
}

@media screen and (min-width: 800px) {
    :root {
        --navbar_options_width: 140px;
    }
    nav{
        position: absolute;
        top: 0;
        height: var(--navbar_height);
        box-shadow: none;
        background-color: transparent;
    }
    nav.scrolled-nav{
        position: fixed;
        top: 0;
        left: 0;
        height: var(--navbar_height);
        background-color: white;
        -webkit-box-shadow: -5px 0 8px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: -5px 0 8px 0px rgba(0,0,0,0.25);
        box-shadow: -5px 0 8px 0px rgba(0,0,0,0.25);
    }
    nav.scrolled-nav .navbar-inner ul li:hover{
        background-color: var(--dark_text);
    }
    nav.scrolled-nav .navbar-inner ul li:hover h1:not(.drop-down ul li h1){
        color: white;
    }
    .navbar-inner .logo{
        display: flex;
    }
    .navbar-inner ul{
        width: auto;
    }
    .navbar-inner ul li{
        width: var(--navbar_options_width);
    }
    .navbar-inner ul li.drop-down{
        width: var(--navbar_options_width);
        flex: 1;
    }
    .navbar-inner ul li.drop-down:hover ul{
        background-color: white;
    }
    .navbar-inner ul li.drop-down:hover ul li:hover{
        background-color: var(--dark_text);
    }
    .navbar-inner ul li.drop-down:hover ul li:hover h1{
        color: white;
    }
    .navbar-inner ul li:hover{
        background-color: white;
    }    
    .navbar-inner ul li.drop-down:hover ul{
        display: flex;
    }
    .navbar-inner ul li ul{
        position: absolute;
        bottom: calc(-2 * var(--navbar_height));
        left: 0;
        width: 100%;
        height: calc(2 * var(--navbar_height));
        display: flex;
        flex-direction: column;
        display: none;
    }
    nav.scrolled-nav .navbar-inner ul li ul{
        background-color: white;
    }
    .navbar-inner ul li h1{
        display: block;
    }
    .navbar-inner ul li a.active{
        background-color: inherit;
    }
    .navbar-inner ul li .icon{
        display: none;
    }
}

@media screen and (min-width: 1100px) {
    :root {
        --navbar_options_width: 150px;
    }
    .navbar-inner ul li{
        width: var(--navbar_options_width);
    }
    .navbar-inner ul li ul li{
        width: var(--navbar_options_width);
    }

}
