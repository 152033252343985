:root{
    --card-width: 213.88px;
    --card-height: 333.33px;
    --book-orange: #e49a21;
}

.books-read-wrapper{
    margin-bottom: 25px;
}

.books-content{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    grid-row-gap: 50px;
    margin-bottom: 50px;
}

.books-card-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-wrapper{
    margin: 25px 0 50px 0;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    user-select: none;
}

.filter{
    position: relative;
    background-color: white;
    min-width: 175px;
    padding: 8px 12px;
    color: var(--dark_text);
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--more_transparent_dark);
}

.filter p{
    color: var(--dark_text);
    font-size: 14px;
    font-family: Nunito-Medium;
    margin-right: 12px;
}

.filter img{
    width: 20px;
    height: auto;
}

.filter .item-container{
    position: absolute;
    bottom: -90.5px;
    left: -0.5%;
    width: 101%;
    height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--more_transparent_dark);
    border-top: none;
    border-radius: 0 0 5px 5px;
}

.item-container span{
    background-color: white;
    color: var(--dark_text);
    transition: var(--transition);
    cursor: pointer;
    height: 30px;
    padding: 0 12px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: Nunito-Medium;
    font-size: 14px;
    z-index: 99999;
}

.item-container span:hover{
    background-color: var(--book-orange);
    color: white;
}

.book-card{
    position: relative;
    width: var(--card-width);
    height: var(--card-height);
    border-radius: 12px;
    overflow: hidden;
    transition: var(--transition);
    cursor: pointer;
    box-shadow: 0 0 8px  var(--more_transparent_dark);
    z-index: 1;
    user-select: none;
}

.book-card:hover{
    transform: rotate(-90deg);
    z-index: 9999;
}

.book-card:hover .back-face{
    opacity: 1;
}

.book-card .front-face{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: var(--transition);
    border-radius: 12px;
    transform: rotate(0);
}

.book-card .front-face img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px
}

.book-card .back-face{
    position: absolute;
    top: 0;
    left: 100%;
    width: var(--card-height);
    height: var(--card-width);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    opacity: 0;
    border-radius: 12px;
    color: white;
    transition: var(--transition);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    padding: 15px 15px;
    background-color: var(--transparent_dark) !important;
}

.book-card .back-face::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--transparent_dark);
    z-index: -1;
}

.book-card .back-face .back-face-content{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    text-align: center;
}

.book-card .back-face h2, .line, h3{
    margin-bottom: 8px;
}

.book-card .back-face h1{
    font-size: 18px !important;
    line-height: 25px;
    letter-spacing: 1px;
    font-family: "Nunito-Black";
}

.book-card .back-face h2{
    position: relative;
    font-size: 15px !important;
    font-weight: 400;
    letter-spacing: 3px;
    font-family: "Nunito-Regular";
}

.book-card .back-face .line{
    width: 95%;
    height: 1px;
    border-radius: 99px;
    background-color: white;
    margin: inherit auto;
    margin: 8px 0;
}

.book-card .back-face .back-face-content .forever-book{
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-card .back-face .back-face-content .forever-book h3{
    margin-bottom: 0;
}

.book-card .back-face .back-face-content .forever-book img{
    width: 24px;
    height: auto;
    margin-left: 4px;
}

.book-card .back-face h3{
    font-size: 14px;
    font-weight: 500;
    font-family: "Nunito-Regular";
}

.book-card .back-face p{
    font-size: 14px;
    font-weight: 400;
    font-family: "Nunito-Medium";
    padding: 0 8px;
}

.book-card .back-face p span{
    font-family: "Nunito-Bold";
    transition: var(--transition);
}

.book-card .back-face p span:hover{
    color: #e49a21;
}

.load-more-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.load-more-wrapper .custom-button{
    width: 85%;
}
@media screen and (max-width: 325px) {
    :root{
        --card-width: 195px;
        --card-height: 303.33px;
    }
    .book-card .back-face h1, h2, .line, h3{
        margin-bottom: 5px;
    }
    .book-card .back-face h1{
        font-size: 18px;
    }
    .book-card .back-face h2{
        font-size: 16px;
    }
    .book-card .back-face h3{
        font-size: 14px;
    }
    .book-card .back-face p{
        font-size: 14px;
    }
}

@media screen and (min-width: 475px) {
    :root{
        --card-width: 213.88px;
        --card-height: 333.33px;
    }
    .load-more-wrapper .custom-button{
        width: 250px;
    }
}

@media screen and (min-width: 800px) {
    .books-content{
        grid-template-columns: 1fr 1fr;
    }
    .load-more-wrapper .custom-button{
        width: 500px;
    }
    .book-card .back-face .line{
        margin: 12px 0;
    }
}

@media screen and (min-width: 1100px) {
    :root{
        --card-width: 250px;
        --card-height: 389.62px;
    }
    .book-card .back-face h1, h2, .line, h3{
        margin-bottom: 12px;
    }
    .book-card .back-face p{
        font-size: 16px;
    }
    .books-content{
        margin-bottom: 75px;
    }
}

@media screen and (min-width: 1250px) {
    .books-content{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .book-card .back-face .line{
        margin: 14px 0;
    }
}

@media screen and (min-width: 1750px) {
    .filter .item-container{
        left: -1%;
        width: 101.5%;
    }
    .books-content{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
