.container{
    width: 100%;
}

.inner{
    width: 90%;
    margin: auto;
}

@media screen and (min-width: 800px) {
    .inner{
        width: 85%;
    }
}

@media screen and (min-width: 1100px) {
    .inner{
        width: 80%;
    }
}

@media screen and (min-width: 1500px) {
    .inner{
        width: 75%;
    }
}