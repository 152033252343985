.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--transparent_dark);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.modal-content{
    width: 350px;
    height: 350px;
    background-color: white;
    border: 1px solid var(--dark_text);
}

.modal-content img{
    width: 100%;
    height: auto;
}

.modal .close-button{
    position: absolute;
    top: 25px;
    right: 25px;
    width: auto;
    height: auto;
    cursor: pointer;
}

.modal .close-button img{
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 325px) {
    .modal-content{
        width: 275px;
        height: 275px;
    }
}

@media screen and (min-width: 475px) {
    .modal-content{
        width: 400px;
        height: 400px;
    }
}

@media screen and (min-width: 800px) {
    .modal-content{
        width: 500px;
        height: 500px;
    }
    .modal .close-button{
        top: 50px;
        right: 50px;
    }
    .modal .close-button img{
        width: 30px;
        height: 30px;
    }    
}

@media screen and (min-width: 1500px) {
    .modal-content{
        width: 650px;
        height: 650px;
    }
    .modal .close-button img{
        width: 35px;
        height: 35px;
    }   
}
