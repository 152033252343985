.visited-content-wrapper{
    margin-bottom: 25px;
}

.visited-content{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
}

.visited-card{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* buttons style sthat on the image and desc  */
.visited-card .buttons{
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.visited-card .buttons img{
    width: 25px;
    height: 25px;
    cursor: pointer;
    opacity: 0.8;
    transition: var(--transition);
}

.visited-card .buttons div{
    margin-bottom: 8px;
}

.visited-card .buttons div:last-of-type{
    margin-bottom: 0;
}

.visited-card .buttons img:hover{
    opacity: 1;
}

/* finish */

.visited-card .img-content{
    position: relative;
    width: 100%;
    height: 100%;
}

.visited-card .img-content{
    width: 100%;
    height: 100%;
}

.visited-card img{
    width: 100%;
    height: auto;
}

.visited-card .desc-content{
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--transparent_dark);
    color: white;
    padding: 15px;
    transition: var(--transition);
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
}

.visited-card .desc-content.active{
    bottom: 0;
}

.visited-card .desc-content h1{
    font-size: 18px;
    font-family: "Nunito-Black";
    letter-spacing: 1px;
    margin-bottom: 8px;
    padding: 0 25px;
}

.visited-card .desc-content .time{
    margin-bottom: 8px;
}

.visited-card .desc-content time{
    font-size: 16px;
    font-family: "Nunito-SemiBold";
    letter-spacing: 1px;
}

.visited-card .desc-content p{
    font-size: 15px;
    font-family: "Nunito-Regular";
    line-height: normal;
}

@media screen and (min-width: 650px) {
    .visited-content{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 800px) {
    .visited-card .desc-content h1{
        font-size: 20px;
        margin-bottom: 15px;
    }
    .visited-card .desc-content .time{
        margin-bottom: 15px;
    }    
}

@media screen and (min-width: 1100px) {
    .visited-card .desc-content p{
        line-height: 28px;
    }
}

@media screen and (min-width: 1250px) {
    .visited-content{
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 35px;
    }
}

@media screen and (min-width: 1500px) {
    .visited-card .desc-content p{
        line-height: 28px;
    }
}

@media screen and (min-width: 1750px) {
    .visited-content{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 50px;
    }
}
