.mobile-page-title {
    position: relative;
    width: 100%;
    height: var(--navbar_height);
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.mobile-page-title h1 {
    font-size: 1.5em;
    font-family: "Nunito-Bold";
    text-transform: uppercase;
    letter-spacing: 1px;
}

header{
    position: relative;
    min-height: 325px;
    display: flex;
    /*justify-content: center;
    align-items: center;*/
    margin-bottom: 25px;
    padding: 0;
}

header .img-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    filter: blur(4px);
    z-index: 1;
}

header .content{
    /*position: absolute;
    top: 0;
    left: 0;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    background-color: var(--transparent_dark);
    z-index: 50;
    text-align: center;
    padding: 25px;
}

header .content p{
    color: white;
    font-family: monospace;
    font-size: 14px;
    line-height: 25px;
}

header .content .custom-button{
    width: 75%;
    padding: .75em 0em;
    margin-top: 1.5em;
    font-size: 14px;
    font-family: monospace;
}

@media screen and (min-width: 475px) {
    header .content{
        padding: 25px 50px;
    }
}

@media screen and (min-width: 800px) {
    .mobile-page-title {
        display: none;
    }
    header {
        height: 500px;
        margin-top: calc(-1 * var(--navbar_height));
        margin-bottom: 50px;
        justify-content: center;
        align-items: center;
    }
    header .content {
        width: 75%;
        height: auto;
        min-height: auto;
        padding: 50px 25px;
        -webkit-box-shadow: 10px 10px 19px -4px rgba(0,0,0,0.5);
        -moz-box-shadow: 10px 10px 19px -4px rgba(0,0,0,0.5);
        box-shadow: 0 10px 19px -4px rgba(0,0,0,0.75);
    }
    header .content .custom-button{
        width: 60%;
        margin-top: 25px;
    }
}
@media screen and (min-width: 1100px) {
    header{
        height: 650px;
        margin-top: calc(-1 * var(--navbar_height));
    }
    header .content{
        width: 60%;
        padding: 75px 30px;
    }
}

/* todo: large screen */
@media screen and (min-width: 1500px) {
    header{
        height: 750px;
    }
    header .content{
        width: 55%;
        padding: 75px 75px;
    }
}

@media screen and (min-width: 1750px) {
    header{
        height: 850px;
    }
    header .content{
        width: 50%;
        padding: 75px 150px;
    }
    header .content .custom-button{
        width: 50%;
        padding: .75em 0em;
        margin-top: 1.5em;
        font-size: 14px;
        font-family: monospace;
    }
}